import { useEffect, useState } from 'react';

export default function usePersistedValue(name, defaultValue) {
  const [liveValue, setLiveValue] = useState(
    localStorage.getItem(name) ?? defaultValue
  );
  useEffect(() => {
    setLiveValue(localStorage.getItem(name) ?? defaultValue);
  }, [name, defaultValue]);

  useEffect(() => {
    localStorage.setItem(name, liveValue);
  }, [liveValue, name]);

  return [liveValue, setLiveValue];
}
