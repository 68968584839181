import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useLanguageStorage from '../../hooks/useLanguageStorage';
import './Header.scss';

export default function Header({ cartJSX }) {
  const { t, i18n } = useTranslation();

  const [, setLanguage] = useLanguageStorage();
  const [offset, setOffset] = useState(false);

  const updateTranslation = code => {
    i18n.changeLanguage(code);
    setLanguage(code);
  };

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className={offset > 2 ? 'header-wrapper active' : 'header-wrapper'}>
      <div className="links">
        <Link to="/">{t('HOME')}</Link> |{' '}
        <Link to="/gallery">{t('GALLERY')} </Link> |{' '}
        <Link to="/merch">{t('MERCH')}</Link>
      </div>
      {/* <Link to="/games">{t('GAMES')}</Link> */}
      {/* <Link to="/gallery" className="merch-link">
        MERCH
      </Link> */}
      {cartJSX && (
        <div className={offset > 2 ? 'cart-wrapper active' : 'cart-wrapper'}>
          {cartJSX}
        </div>
      )}
      <div className="language-wrapper custom-select">
        <select
          value={i18n.language}
          onChange={e => {
            updateTranslation(e.target.value);
          }}>
          <option value="en">EN</option>
          <option value="fr">FR</option>
        </select>
      </div>
    </div>
  );
}
