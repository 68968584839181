import RadioButton from '../ui/RadioButton';
import React, { useState } from 'react';
import axios from 'axios';
import SuperSignStripeElement from './SuperSignStripeElement';
import { useTranslation } from 'react-i18next';

import 'semantic-ui-css/components/popup.min.css';
import { Popup } from 'semantic-ui-react';
import './SuperSign.scss';

export default function SuperSign({ shortlink, onCancel }) {
  const [amount, setAmount] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [custom, setCustom] = useState(false);
  const [wordCount, setWordCount] = React.useState(0);
  const [error, setError] = useState();
  const [clientSecret, setClientSecret] = useState();
  const { t } = useTranslation();
  const setPreselectedAmount = amt => {
    setCustom(false);
    setAmount(amt);
  };
  const isPreSelectedAmountChecked = amt => {
    if (custom) return false;
    return amt === amount;
  };
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await axios.post('/payment-intents', {
        name,
        email,
        shortlink,
        amount: amount * 100
      });
      const { client_secret } = response.data;
      setClientSecret(client_secret);
    } catch (error) {
      if (error?.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
    }
  };

  const handleNameChange = e => {
    setName(e.target.value);
    setWordCount(e.target.value.length);
  };

  if (clientSecret) {
    return (
      <SuperSignStripeElement
        amount={amount}
        onCancel={onCancel}
        clientSecret={clientSecret}
      />
    );
  }

  return (
    <div className="superclown-wrapper">
      <div className="superclown-screen">
        <div className="superclown-content">
          {/* <div className="superclown-title">
            DONATE
            <Popup
              trigger={
                <div className="ss-icon">
                  <i className="fa-duotone fa-circle-info"></i>
                </div>
              }
              position="top center">
              {t('SUPERINFO')}
            </Popup>
          </div> */}

          <form onSubmit={handleSubmit}>
            <div className="radio-wrapper">
              <div className="input-holder">
                <RadioButton
                  label="$5"
                  bg="blue"
                  checked={isPreSelectedAmountChecked(5)}
                  onChange={() => setPreselectedAmount(5)}
                />
                <RadioButton
                  label="$20"
                  bg="green"
                  checked={isPreSelectedAmountChecked(20)}
                  onChange={() => setPreselectedAmount(20)}
                />
                <RadioButton
                  label="$50"
                  bg="red"
                  checked={isPreSelectedAmountChecked(50)}
                  onChange={() => setPreselectedAmount(50)}
                />
                <RadioButton
                  label="$100"
                  bg="brown"
                  checked={isPreSelectedAmountChecked(100)}
                  onChange={() => setPreselectedAmount(100)}
                />

                <input
                  type="text"
                  placeholder={t('OTHER')}
                  className="other"
                  onChange={e => setAmount(Number(e.target.value))}
                />
              </div>
            </div>

            <div className="name-wrapper">
              <div className="nick-wrapper">
                <input
                  type="text"
                  value={name}
                  className="name-input"
                  placeholder={t('NAME')}
                  onChange={handleNameChange}></input>
                <div className="word-count-wrapper">{wordCount}/13</div>
              </div>

              <input
                type="email"
                className="email-input"
                value={email}
                placeholder={t('EMAIL')}
                onChange={e => setEmail(e.target.value)}></input>
              {/* <div className="will-not">(Email will not be displayed)</div> */}
            </div>
            {error && <div className="stripe-error-wrapper">{error}</div>}
            <button type="submit" value="">
              {t('CONTINUE')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
