import axios from 'axios';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useIntersectionDetector } from '../../hooks/useInteresectionDetector';
import Header from '../Header/Header';
import '../../scss/reset.scss';
import '../../scss/global.scss';
import '../../scss/odometer-theme-slot-machine.css';
import './Gallery.scss';
import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/menu.min.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSignedClassName } from '../../lib/styles';
import ReactOdometer from 'react-odometerjs';

import usePersistedValue from '../../hooks/usePersistedValue';

export default function Gallery() {
  const [loading, setLoading] = useState(true);
  const [loadingThumbs, setLoadingThumbs] = useState(true);
  const [clowns, setClowns] = useState({});
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const buttonRef = useRef();
  const [count, setClownCount] = useState(0);
  const [odometerValue, setOdometerValue] = useState(0);

  const [sort, setSort] = usePersistedValue('sort', 'All');
  const loadClownCount = () => {
    axios.get('/clowns?count=true').then(res => {
      setClownCount(res.data.count);
    });
  };
  useEffect(() => {
    loadClownCount();
    const interval = setInterval(loadClownCount, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useIntersectionDetector(() => {
    console.log('Intersection triggered');
    if (pagesAvailable && !loading) {
      setPage(page + 1);
    }
  }, buttonRef.current);
  const [pagesAvailable, setPagesAvailable] = useState(true);

  const loadClownsForPage = (pageNum, sortVar) => {
    axios.get(`/clowns?sort=${sortVar}&page=${pageNum}`).then(response => {
      setPagesAvailable(response.data.length !== 0);

      setClowns(current => ({ ...current, [pageNum]: response.data }));
      setLoading(false);
    });
  };
  useEffect(() => {
    setLoading(true);
    loadClownsForPage(page, sort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    console.log(sort);
    setClowns({});

    setPage(1);
    loadClownsForPage(1, sort);
  }, [sort]);

  useEffect(() => {
    const interval = setInterval(loadClownsForPage.bind({}, 1, sort), 5000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  // Load this effect on mount
  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setLoadingThumbs(false);
    }, 1500);
    // Cancel the timer while unmounting
    return () => clearTimeout(timer);
  }, []);

  document.body.style.opacity = '1';

  useEffect(() => {
    const setTitle = async () => {
      document.title = 'Trudeau is a Clown: Gallery';
    };
    setTitle();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOdometerValue(count);
    }, 0);
  }, [count]);

  const allClowns = Object.values(clowns).flat();

  return (
    <div className="page-gallery">
      <Header />
      <div className="main">
        <div className="odometer-wrapper">
          <div className="odometer-container">
            <ReactOdometer value={odometerValue} />
            <span className="clowns-generated">
              <div
                dangerouslySetInnerHTML={{
                  __html: t('GENERATINGCLOWNS', {
                    interpolation: { escapeValue: true }
                  })
                }}
              />
            </span>
          </div>
        </div>
        <div className="sort-wrapper">
          <div className="ui compact menu">
            <div
              role="listbox"
              aria-expanded="false"
              className="ui item simple dropdown"
              tabindex="0">
              <div
                aria-atomic="true"
                aria-live="polite"
                role="alert"
                className="divider text">
                {t('SORT1')}
                <span>{sort}</span>
              </div>
              <i aria-hidden="true" className="dropdown icon"></i>
              <div className="menu transition">
                <div
                  role="option"
                  aria-checked="false"
                  aria-selected="true"
                  className="selected item"
                  onClick={setSort.bind({}, 'Super')}>
                  <span className="text">{t('SORT2')}</span>
                </div>
                <div
                  role="option"
                  aria-checked="false"
                  aria-selected="false"
                  className="item"
                  onClick={setSort.bind({}, 'All')}>
                  <span className="text">{t('SORT3')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className="image-gallery">
          {loadingThumbs &&
            Array(40)
              .fill()
              .map((item, index) => (
                <li className="skeleton-card" key={index}>
                  <span>
                    <i></i>
                  </span>
                </li>
              ))}

          {!loadingThumbs &&
            allClowns.map(clown => {
              return (
                <li
                  key={clown.shortlink}
                  className={
                    clown.signed ? getSignedClassName(clown.amount) : ''
                  }>
                  {/* <Link
                    onClick={e => {
                      e.preventDefault();
                      setSelectedClown(clown);
                      setClownModalOpen(true);
                    }}
                    to={`/c/${clown.shortlink}`}>
                    <div className="img-hover-zoom">
                      <img src={clown.url} alt={'Clown'} />
                    </div>
                    <div className="overlay">
                      <span>{`#` + clown.id}</span>
                    </div>
                  </Link> */}

                  <Link to={`/c/${clown.shortlink}`} target="_blank">
                    <div className="img-hover-zoom">
                      <img src={clown.url} alt={'Clown'} />
                    </div>
                    <div className="overlay">
                      <span>{`#` + clown.id}</span>
                    </div>
                  </Link>

                  <p className="sig">
                    <span className="first">
                      {clown.signed && <i className="fa-regular fa-stars"></i>}
                    </span>
                    <span className="second">
                      {clown.signed ? (
                        <>
                          <i className="fa-duotone fa-signature"></i>{' '}
                          {clown.signer_name}
                        </>
                      ) : (
                        <>#{clown.id}</>
                      )}
                    </span>
                  </p>
                </li>
              );
            })}
        </ul>
        {pagesAvailable && (
          <div className="button-wrapper">
            <button ref={buttonRef} disabled={true}>
              Loading more Trudeau clowns &#129313;...
            </button>
          </div>
        )}
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
