// Games.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import '../../scss/reset.scss';
import '../../scss/global.scss';
import Cart from './Cart';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Merch.scss';

const Games = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [cart, setCart] = useState([]);
  const [buttonText, setButtonText] = useState('Add to Cart');
  const [isAdding, setIsAdding] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [isCartOpen, setCartOpen] = useState(false);

  useEffect(() => {
    // Load products and cart from local storage on component mount
    loadProducts();
    loadCart();
  }, []);

  useEffect(() => {
    // Save cart to local storage whenever it changes
    saveCart();
  }, [cart]);

  const loadProducts = async () => {
    await axios.get(`/products`).then(response => {
      setProducts(response.data);
      setLoading(false);
    });
  };
  const handleToggleCart = () => {
    setCartOpen(!isCartOpen);
  };
  const loadCart = () => {
    const storedCart = localStorage.getItem('cart');
    setCart(storedCart ? JSON.parse(storedCart) : []);
  };

  const saveCart = () => {
    console.log(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  const updateQuantity = (productId, newQuantity) => {
    setCart(prevCart =>
      prevCart.map(item =>
        item.productId === productId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const removeFromCart = productId => {
    setCart(prevCart => prevCart.filter(item => item.productId !== productId));
  };

  const addToCart = productId => {
    const existingItem = cart.find(item => item.productId === productId);

    if (existingItem) {
      // If the item is already in the cart, update its quantity
      updateQuantity(productId, existingItem.quantity + 1);
    } else {
      // If the item is not in the cart, add it as a new item
      const product = products.find(
        product => product.variants[0].id === productId
      );
      const newItem = {
        productId,
        title: product.title,
        quantity: 1,
        price: product.variants[0].price,
        image: product.image.src
      };

      setCart(prevCart => [...prevCart, newItem]);
    }
  };
  const clearCart = () => {
    setCart([]);
    saveCart();
  };

  const handleButtonClick = async buttonId => {
    setLoadingStates(prevLoadingStates => ({
      ...prevLoadingStates,
      [buttonId]: true
    }));
    await new Promise(resolve => setTimeout(resolve, 1000));
    setLoadingStates(prevLoadingStates => ({
      ...prevLoadingStates,
      [buttonId]: false
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (selectedProductId) {
      addToCart(selectedProductId);
      handleToggleCart();
    }
  };

  const redirectToCheckout = checkoutUrl => {
    window.location.href = checkoutUrl;
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const cartJSX = (
    <Cart
      cart={cart}
      updateQuantity={updateQuantity}
      removeFromCart={removeFromCart}
      redirectToCheckout={redirectToCheckout}
      handleToggleCart={handleToggleCart}
      isCartOpen={isCartOpen}
    />
  );

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/pm-dressup');
  };

  useEffect(() => {});
  return (
    <div className="page-merch">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Trudeau is a clown | Shop official Justin Trudeau clown merch
        </title>
        <link rel="canonical" href="http://trudeauisaclown.com/merch" />
      </Helmet>
      <Header cartJSX={cartJSX} />
      <div className="main">
        <div className="merch-wrapper">
          <div className="merch-top-desc">
            <div className="top-merch-banner">
              <span>T-shirts coming soon!</span>{' '}
              <span>Free shipping on orders over $100.</span>
            </div>
            <p>
              All merch sales help keep this website running. Thanks for your
              support!
            </p>
          </div>
          <div className="grid-wrapper">
            {/* <li>
              <div className="product">
                <Slider {...settings}>
                  <div>
                    <img
                      src="/images/framed.jpg"
                      alt="PM Dressup framed card collection"
                    />
                  </div>
                  <div>
                    <img
                      src="/images/framed-1.jpg"
                      alt="Justin Trudeau dress as The Arabian"
                    />
                  </div>
                  <div>
                    <img
                      src="/images/framed-2.jpg"
                      alt="Justin Trudeau dress as The Boxer"
                    />
                  </div>
                  <div>
                    <img
                      src="/images/framed-3.jpg"
                      alt="Justin Trudeau dress as The Hinduist"
                    />
                  </div>
                  <div>
                    <img
                      src="/images/framed-4.jpg"
                      alt="Justin Trudeau dress as The Cowboy"
                    />
                  </div>
                </Slider>
                <div className="product-desc-wrapper">
                  <h3>PM DRESSUP</h3>

                  <div className="product-desc">
                    PM Dressup: Collector Card Set Framed *Limited Edition*
                  </div>
                  <p>$299.00 CAD</p>
                  <form method="post" onSubmit={handleSubmit}>
                    <input
                      type="submit"
                      value="PRE-ORDER"
                      className="addto_cart_btn preorder-btn"
                      onClick={() => handleClick()}
                      disabled={isAdding}
                    />
                  </form>
                </div>
              </div>
            </li> */}
            {products.map(product => (
              <li key={product.id}>
                <div className="product">
                  <Slider {...settings}>
                    {product.images.map(image => (
                      <div key={image.src}>
                        <img src={image.src} alt="" />
                      </div>
                    ))}
                  </Slider>
                  <div className="product-desc-wrapper">
                    <h3>{product.title}</h3>
                    <div className="product-desc">{product.body_html}</div>
                    <p>${product.variants[0].price + ` CAD`}</p>
                    <form method="post" onSubmit={handleSubmit}>
                      <input
                        type="submit"
                        value={isAdding ? 'Adding...' : buttonText}
                        className="addto_cart_btn"
                        onClick={() =>
                          setSelectedProductId(product.variants[0].id)
                        }
                        disabled={isAdding}
                      />
                    </form>
                  </div>
                </div>
              </li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Games;
