import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header/Header';
import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';
import 'semantic-ui-css/components/button.min.css';
import '../../scss/reset.scss';
import '../../scss/global.scss';
import './Dressup.scss';
import Recaptcha from '../Recaptcha';
export default function Dressup() {
  const { t } = useTranslation();
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState();

  const handleSubmit = e => {
    e.preventDefault();
    setStatus('Sending...');

    let details = {
      name: 'PM DRESSUP PREORDER',
      email: email,
      message: 'PM DRESSUP PREORDER',
      token
    };
    axios
      .post('/sendcontact', details)
      .then(() => {
        setStatus('Thanks for your interest.');
        setEmail('');
      })
      .catch(e => {
        setStatus('Message could not be sent.');
      });
    e.preventDefault();
  };

  useEffect(() => {
    const setTitle = async () => {
      document.title = 'Trudeau is a Clown: PM Dressup Card Collection';
    };
    setTitle();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-dressup">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trudeau is a clown | Contact us</title>
        <link rel="canonical" href="http://trudeauisaclown.com/contact" />
      </Helmet>
      <Header />
      <div className="main">
        <div className="contact-wrapper">
          <div className="dressup-content">
            <h1>PM DRESSUP</h1>
            <p>
              A 23 card collection set inspired by Canada's 23rd Prime Minister
              Justin Trudeau which commemorates his most memorable costumes.
            </p>
            <img src="/images/framed-set.png" />
            <p>
              These eye-catching and iconic illustrations are based on{' '}
              <a
                href="https://macleans.ca/multimedia/photo/the-mr-dressup-prime-minister/"
                target="_blank"
                rel="noreferrer">
                actual costumes
              </a>{' '}
              that Justin Trudeau has been photographed wearing throughout the
              years.
            </p>
            <br />
            <p>$299.00 CAD</p>
            <br />
            <p className="join">
              Join the wait-list and get notified first when available:
            </p>

            <form onSubmit={handleSubmit}>
              <div>
                <input
                  placeholder="Email*"
                  type="email"
                  id="email"
                  value={email}
                  required
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <Recaptcha onChange={token => setToken(token)} />
              <button disabled={!token || token === ''} type="submit">
                Submit
              </button>
              <div className="response-wrapper">
                <p>{status}</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
