import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import 'react-accessible-accordion/dist/fancy-example.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

import '../../scss/reset.scss';
import '../../scss/global.scss';
import './Home.scss';

export default function Games() {
  const { t } = useTranslation();
  const [odometerValue, setOdometerValue] = useState(0);
  const [count, setClownCount] = useState(0);

  const loadClownCount = () => {
    axios.get('/clowns?count=true').then(res => {
      setClownCount(res.data.count);
    });
  };

  useEffect(() => {
    const setTitle = async () => {
      document.title = 'Trudeau is a Clown: Home';
    };
    setTitle();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadClownCount();
    setTimeout(() => {
      setOdometerValue(count);
    }, 0);
  }, [count]);

  const hostUrl = 'https://trudeauisaclown.com';

  const socialList = [
    {
      id: '1',
      class: 'fa-brands fa-square-twitter',
      callback: event => {
        event.preventDefault();
        axios.get('/tweet?url=' + hostUrl).finally(() => {
          setTimeout(
            () =>
              window.open(`http://twitter.com/intent/tweet?text=${hostUrl}`),
            400
          );
        });
      },
      url: `#`,
      img: `/images/square-twitter.svg`,
      alt: 'twitter icon'
    },
    {
      id: '2',
      class: 'fa-brands fa-square-facebook',
      callback: () => {},
      url: `https://www.facebook.com/sharer.php?u=${hostUrl}`,
      img: `/images/square-facebook.svg`,
      alt: 'facebook icon'
    }
  ];

  return (
    <div className="page-home-modal">
      <div className="main">
        <div className="home-wrapper">
          <div className="home-description">
            <div className="giveaway-wrapper">
              <div className="top-wrapper">
                <h1>
                  The Million
                  <br /> Clown Mission
                </h1>
              </div>
              <div className="modal-wrapper-home">
                <div className="goal-text">
                  Goal: <span>{1000000 - odometerValue}</span> Trudeau clowns
                  remaining
                </div>
                <ul className="skill-list">
                  <li className="skill">
                    <h3>
                      {odometerValue} <span>/ 1,000,000 clowns generated</span>
                    </h3>
                    <progress className="skill-1" max="100" value="1">
                      <strong>Percentage: 50%</strong>
                    </progress>
                  </li>
                </ul>
                <div className="keychain-wrapper">
                  <h2>Be a part of history!</h2>
                  <p className="second">
                    Help us generate 1,000,000 AI Trudeau clowns and we'll build
                    a life sized Justin Trudeau clown statue!
                  </p>
                  <br />
                  <h2>FAQ</h2>
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Is this for real?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          100% real. If we reach our goal, we will build and
                          unveil a physical, life sized Justin Trudeau clown
                          statue.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Why are you doing this?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We've had enough of Trudeau's careless spending and
                          disrespect for his own citizen's. We want to give
                          people a voice and make a collective statement on the
                          behalf of all Canadians who have been affected.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          How do I get involved?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Generate clowns for free using the machine on our
                          homepage to help us reach our target goal of 1 million
                          clowns.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Who is paying for all of this?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          While not necessary{' '}
                          <a
                            href="https://donate.stripe.com/eVag2K55p0gfgw0144"
                            target="_blank"
                            rel="noreferrer">
                            donations
                          </a>{' '}
                          are always greatly appreciated!
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Where will the statue be located?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Location is yet TBD. Likely somewhere in Western
                          Canada.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Is there a deadline?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Although we don't have a set deadline, we hope to
                          reach 1 million clowns before the next election or
                          while Trudeau remains in office.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>

                  <br />
                  <h2>Help us spread the word!</h2>
                  {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  ac quam erat. Integer laoreet posuere odio, vel sagittis diam
                  viverra ut. Vivamus sed orci elementum lorem aliquet porta eu
                  placerat mauris.
                </p> */}
                  <div className="social-wrapper">
                    {socialList.map(social => {
                      return (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          onClick={social.callback}
                          href={social.url}
                          key={social.id}>
                          <img
                            src={social.img}
                            alt={social.alt}
                            className={social.class}
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>

                <div className="giveaway-text">
                  <div class="sketchfab-embed-wrapper">
                    <iframe
                      title="Justin Trudeau V3"
                      frameborder="0"
                      allowfullscreen
                      mozallowfullscreen="true"
                      webkitallowfullscreen="true"
                      allow="autoplay; fullscreen; xr-spatial-tracking"
                      xr-spatial-tracking
                      execution-while-out-of-viewport
                      execution-while-not-rendered
                      web-share
                      width="100%"
                      height="380"
                      src="https://sketchfab.com/models/0b10989f3ca846e3b1db31f6708ca94e/embed?autostart=1&ui_theme=dark&ui_hint=2&dnt=1&autospin=1"></iframe>
                  </div>
                  <p className="art-rendering">Artistic Rendering</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
