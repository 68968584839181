import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header/Header';
import SuperSign from '../Interface/SuperSign';

import { useTranslation } from 'react-i18next';
import 'semantic-ui-css/components/button.min.css';
import '../../scss/reset.scss';
import '../../scss/global.scss';
import './Donate.scss';
export default function Donate() {
  const { t } = useTranslation();

  useEffect(() => {
    const setTitle = async () => {
      document.title = 'Trudeau is a Clown: Donate';
    };
    setTitle();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-donate">
      <Header />
      <div className="main">
        <div className="contact-wrapper">
          <h1>{t('DONATE')}</h1>
          <p>
            AI image processing is gpu/resource intensive and the cloud servers
            are costly to run. Help keep this website running and support future
            development, new games, etc. Your support is greatly appreciated.
          </p>
          <SuperSign onCancel={null} shortlink={1} />
        </div>
      </div>
    </div>
  );
}
