import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header/Header';
import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';
import 'semantic-ui-css/components/button.min.css';
import '../../scss/reset.scss';
import '../../scss/global.scss';
import './Contact.scss';
import Recaptcha from '../Recaptcha';
export default function Contact() {
  const { t } = useTranslation();
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState();

  const handleSubmit = e => {
    e.preventDefault();
    setStatus('Sending...');

    let details = {
      name: name,
      email: email,
      message: message,
      token
    };
    axios
      .post('/sendcontact', details)
      .then(() => {
        setStatus('Thanks for contacting us.');
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch(e => {
        setStatus('Message could not be sent.');
      });
    e.preventDefault();
  };

  useEffect(() => {
    const setTitle = async () => {
      document.title = 'Trudeau is a Clown: Contact';
    };
    setTitle();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-contact">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trudeau is a clown | Contact us</title>
        <link rel="canonical" href="http://trudeauisaclown.com/contact" />
      </Helmet>
      <Header />
      <div className="main">
        <div className="contact-wrapper">
          <h1>{t('CONTACT')}</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                placeholder="Name*"
                type="text"
                id="name"
                value={name}
                required
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div>
              <input
                placeholder="Email*"
                type="email"
                id="email"
                value={email}
                required
                onChange={e => setEmail(e.target.value)}
              />
            </div>

            <div>
              <textarea
                placeholder="Message*"
                id="message"
                value={message}
                required
                onChange={e => setMessage(e.target.value)}
              />
            </div>
            <Recaptcha onChange={token => setToken(token)} />
            <button disabled={!token || token === ''} type="submit">
              Submit
            </button>
            <div className="response-wrapper">
              <p>{status}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
