import { useEffect, useRef, useState, useCallback } from 'react';
export default function Recaptcha({ onChange }) {
  const scriptPath = 'https://www.google.com/recaptcha/api.js?render=explicit';
  useEffect(() => {
    const script = document.createElement('script');
    script.src = scriptPath;
    document.body.appendChild(script);
  }, []);

  const divRef = useRef(null);
  const [count, setCount] = useState(0);
  const callbackFn = useCallback(data => {
    onChange(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!window.grecaptcha?.render) {
      setTimeout(() => setCount(count + 1), 500);
      return;
    }
    if (count === -1) {
      return;
    }

    try {
      window.grecaptcha.render('recaptchaElement', {
        siteKey: process.env.REACT_APP_CAPTCHA_KEY,
        callback: callbackFn
      });
    } catch (eror) {
      console.log(eror);
    }
    setCount(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef.current, count]);

  return (
    <>
      <div>
        <div
          id="recaptchaElement"
          ref={divRef}
          className="g"
          data-sitekey={process.env.REACT_APP_CAPTCHA_KEY}
        />
      </div>
    </>
  );
}
