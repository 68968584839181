import { useEffect, useState } from 'react';

export const useIntersectionDetector = (callback, element) => {
  const [wasIntersecting, setWasIntersecting] = useState(false);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 1
    };

    const handleObserver = entries => {
      const target = entries[0];

      if (target.isIntersecting && !wasIntersecting) {
        callback();
        setWasIntersecting(true);
      } else {
        setWasIntersecting(!wasIntersecting);
      }
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (element) observer.observe(element);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, callback]);
};
