import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Clown from '../Clown/Clown';
import Gallery from '../Gallery/Gallery';
// import Games from '../Games/Games';
import Home from '../Home/Home';
import Merch from '../Merch/Merch';
import Contact from '../Contact/Contact';
import Dressup from '../Dressup/Dressup';
import Donate from '../Donate/Donate';
import MerchFinished from '../Merch/MerchFinished';

const Interface = React.lazy(() => import('../Interface/Interface'));

function Router() {
  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          <Route path="/" element={<Interface />} />
          <Route path="/generate" element={<Interface />} />
          <Route path="/generate-clowns" element={<Interface />} />
          <Route path="/clowntron" element={<Interface />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* <Route path="/games" element={<Games />} /> */}
          <Route path="/merch" element={<Merch />} />
          <Route path="/finished" element={<MerchFinished />} />
          <Route path="/home" element={<Home />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pm-dressup" element={<Dressup />} />
          <Route path="/c/:clownId" element={<Clown />} />
          <Route element={<>No route found</>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
