import React, { useState } from 'react';
const Cart = ({
  cart,
  updateQuantity,
  removeFromCart,
  redirectToCheckout,
  handleToggleCart,
  isCartOpen
}) => {
  const handleCheckout = () => {
    // Create the checkout URL based on the cart items
    const checkoutUrl = `https://83dedb.myshopify.com/cart/${cart
      .map(item => `${item.productId}:${item.quantity}`)
      .join(',')}`;

    // Redirect to the checkout URL
    redirectToCheckout(checkoutUrl);
  };

  const getTotalItemCount = () => {
    return cart.reduce((totalCount, item) => totalCount + item.quantity, 0);
  };

  // Function to calculate the total cost of items in the cart
  const getTotalCartCost = () => {
    return cart.reduce(
      (totalCost, item) => totalCost + item.price * item.quantity,
      0
    );
  };

  return (
    <>
      <div
        className={`cart-black-overlay ${isCartOpen ? 'active' : ''}`}
        onClick={handleToggleCart}></div>
      <div onClick={handleToggleCart} className="cart-icon">
        <span className="count">{getTotalItemCount()}</span>
        <svg
          className="at-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256">
          <path d="M251,51.9c-4.7-5.4-11.8-8.2-21.3-8.2H49.5L44.7,0.7H10.1C4.5,0.7,0,5.2,0,10.8s4.5,10.1,10.1,10.1h16.5l15.2,135.3 c1.1,11.2,9.8,32.2,34.5,32.2h127.9l0.1,0c9.1-0.2,27.2-6.9,31-30.4l20.3-89.1l0.1-0.8C256.1,66.6,256.9,58.7,251,51.9z M215.6,153.8l-0.1,0.7c-1.8,11.8-9.2,13.4-11.6,13.7H76.4c-5.4,0-9-1.9-11.6-6.1c-2.3-3.8-2.8-7.9-2.8-7.9L51.8,64h177.9 c4.2,0,5.8,1,6,1.2c0,0,0,0.1,0.1,0.2L215.6,153.8z"></path>
          <path d="M83.4,198.2c-15.8,0-28.6,12.8-28.6,28.6c0,15.8,12.8,28.6,28.6,28.6s28.6-12.8,28.6-28.6C112,211,99.2,198.2,83.4,198.2z M83.4,237.1c-5.7,0-10.4-4.7-10.4-10.4s4.7-10.4,10.4-10.4c5.7,0,10.4,4.7,10.4,10.4S89.1,237.1,83.4,237.1z"></path>
          <path d="M183,198.2c-15.8,0-28.6,12.8-28.6,28.6c0,15.8,12.8,28.6,28.6,28.6s28.6-12.8,28.6-28.6C211.5,211,198.7,198.2,183,198.2z M183,237.1c-5.7,0-10.4-4.7-10.4-10.4s4.7-10.4,10.4-10.4s10.4,4.7,10.4,10.4S188.7,237.1,183,237.1z"></path>
        </svg>
      </div>
      <div className={`cart flyout-cart ${isCartOpen ? 'active' : ''}`}>
        <div className="main-close-cart">
          <h2>Your Cart ({getTotalItemCount()})</h2>
          <span className="close-cart" onClick={handleToggleCart}>
            <svg className="at-icon" viewBox="0 0 384 512">
              <path d="M231.6 256l130.1-130.1c4.7-4.7 4.7-12.3 0-17l-22.6-22.6c-4.7-4.7-12.3-4.7-17 0L192 216.4 61.9 86.3c-4.7-4.7-12.3-4.7-17 0l-22.6 22.6c-4.7 4.7-4.7 12.3 0 17L152.4 256 22.3 386.1c-4.7 4.7-4.7 12.3 0 17l22.6 22.6c4.7 4.7 12.3 4.7 17 0L192 295.6l130.1 130.1c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17L231.6 256z"></path>
            </svg>
          </span>
        </div>
        <ul>
          {cart.map(
            item =>
              item.quantity > 0 && (
                <li key={item.productId}>
                  <div className="cart-item">
                    <div className="cart-image">
                      <img src={item.image} alt={item.title} />
                    </div>
                    <div className="cart-details">
                      <p>{item.title}</p>
                      <div className="quantity">
                        <button
                          onClick={() =>
                            updateQuantity(item.productId, item.quantity - 1)
                          }>
                          -
                        </button>
                        <span>{item.quantity}</span>
                        <button
                          onClick={() =>
                            updateQuantity(item.productId, item.quantity + 1)
                          }>
                          +
                        </button>
                      </div>
                      <button
                        className="remove-btn"
                        onClick={() => removeFromCart(item.productId)}>
                        <svg
                          className="at-icon"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <g id="Icon">
                            <path d="m19.75 6c0-.414-.336-.75-.75-.75h-14c-.414 0-.75.336-.75.75v13.5c0 .862.342 1.689.952 2.298.609.61 1.436.952 2.298.952h9c.862 0 1.689-.342 2.298-.952.61-.609.952-1.436.952-2.298 0-4.5 0-13.5 0-13.5zm-1.5.75v12.75c0 .464-.184.909-.513 1.237-.328.329-.773.513-1.237.513-2.542 0-6.458 0-9 0-.464 0-.909-.184-1.237-.513-.329-.328-.513-.773-.513-1.237v-12.75z"></path>
                            <path d="m16.5 6.75c.414 0 .75-.336.75-.75 0 0 0-1 0-2 0-.729-.29-1.429-.805-1.945-.516-.515-1.216-.805-1.945-.805-1.488 0-3.512 0-5 0-.729 0-1.429.29-1.945.805-.515.516-.805 1.216-.805 1.945v2c0 .414.336.75.75.75zm-.75-1.5h-7.5v-1.25c0-.332.132-.649.366-.884.235-.234.552-.366.884-.366h5c.332 0 .649.132.884.366.234.235.366.552.366.884z"></path>
                            <path d="m20 5.25h-16c-.414 0-.75.336-.75.75s.336.75.75.75h16c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z"></path>
                            <path d="m8.75 10.5v7c0 .414.336.75.75.75s.75-.336.75-.75v-7c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"></path>
                            <path d="m13.75 10.5v7c0 .414.336.75.75.75s.75-.336.75-.75v-7c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </li>
              )
          )}
        </ul>
        {cart.length > 0 && (
          <>
            <div className="main-checkout">
              <div className="total">
                <span>Total</span>
                <span>${getTotalCartCost().toFixed(2)} CAD</span>
              </div>
              <div className="checkout-message">
                <p>Taxes and shipping calculated at checkout</p>
              </div>
              <div className="checkout">
                <button onClick={handleCheckout}>PROCEED TO CHECKOUT</button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Cart;
