import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getSignedClassName } from '../../lib/styles';
import Header from '../Header/Header';
import { useTranslation } from 'react-i18next';
import 'semantic-ui-css/components/accordion.min.css';
import { Accordion, Icon } from 'semantic-ui-react';
import '../../scss/reset.scss';
import '../../scss/global.scss';
import './Clown.scss';
import { useSwipeable } from 'react-swipeable';

export default function Clown() {
  const { clownId } = useParams();
  const [clown, setClown] = useState();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const [swiping, setSwiping] = useState(false);
  const navigate = useNavigate();
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (!clown.next) return;
      navigate(`/c/${clown.next}`);
      setSwiping(true);
    },
    onSwipedRight: () => {
      if (!clown.previous) return;
      navigate(`/c/${clown.previous}`);
      setSwiping(true);
    }
  });
  useEffect(() => {
    axios
      .get('/clowns/' + clownId)
      .then(sleeper(900))
      .then(response => {
        setSwiping(false);
        setClown(response.data);
      })
      .catch(() => {
        setSwiping(false);
        setClown(null);
      });
    window.scrollTo(0, 0);
  }, [clownId]);

  function sleeper(ms) {
    return function (x) {
      return new Promise(resolve => setTimeout(() => resolve(x), ms));
    };
  }

  const hostUrl = `${process.env.REACT_APP_BACKEND_URL}/c/${clownId}`;

  console.log('** hostUrl', hostUrl);

  const socialList = [
    {
      id: '1',
      class: 'fa-brands fa-square-twitter',
      callback: event => {
        event.preventDefault();
        axios.get('/tweet?url=' + hostUrl).finally(() => {
          setTimeout(
            () =>
              window.open(`http://twitter.com/intent/tweet?text=${hostUrl}`),
            400
          );
        });
      },
      url: `#`,
      img: `/images/square-twitter.svg`,
      alt: 'twitter icon'
    },
    {
      id: '2',
      class: 'fa-brands fa-square-facebook',
      callback: () => {},
      url: `https://www.facebook.com/sharer.php?u=${hostUrl}`,
      img: `/images/square-facebook.svg`,
      alt: 'facebook icon'
    }
  ];

  const { t } = useTranslation();

  useEffect(() => {
    const setTitle = async () => {
      if (clown) document.title = 'Trudeau is a Clown #' + clown.id;
    };
    setTitle();
  }, [clown]);

  const handleToggle = index => {
    if (isAccordionOpen) {
      setIsAccordionOpen(false);
    } else {
      setIsAccordionOpen(true);
    }
  };

  return (
    <div className="clown-wrapper" {...handlers}>
      <Header />

      <div
        className={
          clown
            ? `image-wrapper ${getSignedClassName(clown.amount)}`
            : 'image-wrapper'
        }>
        <div className="status-wrapper">
          {clown === undefined && (
            <>
              <p className="loading-wrapper">
                Loading Trudeau Clown &#129313;...
              </p>
              <div className="skeleton-clown"></div>
            </>
          )}
          {clown === null && <span>Clown not found.</span>}
        </div>

        {clown && (
          <>
            <div className="details-wrapper">
              {swiping && <div className="loading-swipe">Loading...</div>}
              <img
                src={`${clown.url}`}
                className={swiping ? 'swipe-image' : ''}
                alt="clown illustration"
              />
              <div className="details-wrapper-text">
                <div className="top-wrapper">
                  {clown.previous && (
                    <Link to={`/c/${clown.previous}`} className="point-left">
                      <i className="fa-sharp fa-regular fa-chevron-left"></i>
                    </Link>
                  )}
                  {clown.next && (
                    <Link to={`/c/${clown.next}`} className="point-right">
                      <i className="fa-sharp fa-regular fa-chevron-right"></i>
                    </Link>
                  )}
                  <h1>
                    {t('CLOWN')} #{clown.id}
                  </h1>{' '}
                  <div className="social-wrapper">
                    {socialList.map(social => {
                      return (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          onClick={social.callback}
                          href={social.url}
                          key={social.id}>
                          <img
                            src={social.img}
                            alt={social.alt}
                            className={social.class}
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
                {clown.signed && (
                  <div className="signed-by-wrapper">
                    <p>
                      {t('SIGNEDBY')}: {clown.signer_name}
                    </p>
                  </div>
                )}

                <Accordion styled>
                  <Accordion.Title
                    active={isAccordionOpen}
                    index={0}
                    onClick={() => handleToggle()}>
                    <Icon name="dropdown" />
                    {t('DETAILS')}
                  </Accordion.Title>
                  <Accordion.Content active={isAccordionOpen}>
                    <div className="details-wrapper-inside">
                      <p>
                        {t('HEIGHT')}: {clown.meta.H || '512'}
                      </p>
                      <p>
                        {t('WIDTH')}: {clown.meta.W || '512'}
                      </p>
                      <p>
                        {t('SEED')}: {clown.meta.seed}
                      </p>
                      <p>
                        {t('PREFIX')}: {clown.meta.file_prefix}
                      </p>
                      <p>
                        {t('GUIDANCE')}: {clown.meta.guidance_scale || '13'}{' '}
                      </p>
                      <p>
                        {t('MODEL')}:{' '}
                        {clown.meta.model || 'stable-diffusion-v1-6'}
                      </p>
                      <p>
                        {t('REVISION')}: {clown.meta.revision || 'fp16'}
                      </p>
                      <p>
                        {t('STEPS')}: {clown.meta.steps || '30'}
                      </p>
                      <p>
                        {t('SAMPLER')}:{' '}
                        {clown.meta.vae || 'stabilityai/k-dpmpp-2'}
                      </p>
                      <p>Justin Trudeau is a clown</p>
                    </div>
                  </Accordion.Content>
                </Accordion>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
