// Games.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header/Header';
import { useTranslation } from 'react-i18next';
import '../../scss/reset.scss';
import '../../scss/global.scss';
import Cart from './Cart';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Merch.scss';
import { useNavigate } from 'react-router-dom';

const MerchFinished = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [cart, setCart] = useState([]);
  const [buttonText, setButtonText] = useState('Add to Cart');
  const [isAdding, setIsAdding] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [isCartOpen, setCartOpen] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    // Load products and cart from local storage on component mount
    loadProducts();
    loadCart();
  }, []);

  useEffect(() => {
    // Save cart to local storage whenever it changes
    saveCart();
  }, [cart]);

  const loadProducts = async () => {
    await axios.get(`/products`).then(response => {
      setProducts(response.data);
      setLoading(false);
    });
  };
  const handleToggleCart = () => {
    setCartOpen(!isCartOpen);
  };
  const loadCart = () => {
    const storedCart = localStorage.getItem('cart');
    setCart(storedCart ? JSON.parse(storedCart) : []);
  };

  const saveCart = () => {
    console.log(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  const updateQuantity = (productId, newQuantity) => {
    setCart(prevCart =>
      prevCart.map(item =>
        item.productId === productId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const removeFromCart = productId => {
    setCart(prevCart => prevCart.filter(item => item.productId !== productId));
  };

  const addToCart = productId => {
    const existingItem = cart.find(item => item.productId === productId);

    if (existingItem) {
      // If the item is already in the cart, update its quantity
      updateQuantity(productId, existingItem.quantity + 1);
    } else {
      // If the item is not in the cart, add it as a new item
      const product = products.find(
        product => product.variants[0].id === productId
      );
      const newItem = {
        productId,
        title: product.title,
        quantity: 1,
        price: product.variants[0].price,
        image: product.image.src
      };

      setCart(prevCart => [...prevCart, newItem]);
    }
  };
  const clearCart = () => {
    setCart([]);
    saveCart();
  };

  const handleButtonClick = async buttonId => {
    setLoadingStates(prevLoadingStates => ({
      ...prevLoadingStates,
      [buttonId]: true
    }));
    await new Promise(resolve => setTimeout(resolve, 1000));
    setLoadingStates(prevLoadingStates => ({
      ...prevLoadingStates,
      [buttonId]: false
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (selectedProductId) {
      addToCart(selectedProductId);
      handleToggleCart();
    }
  };

  const redirectToCheckout = checkoutUrl => {
    window.location.href = checkoutUrl;
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  useEffect(() => {
    clearCart();
    navigate('/merch');
  }, []);
  return (
    <div className="page-merch">
      <Header />
      <div className="main">
        <div className="merch-wrapper">
          <div className="merch-top-desc">
            <div className="top-merch-banner">
              T-shirts coming soon! Free shipping on orders over $100.
            </div>
            <p>
              All merch sales help keep this website running. Thanks for your
              support!
            </p>
          </div>
          Thank you for shopping with us!
        </div>
      </div>
    </div>
  );
};

export default MerchFinished;
