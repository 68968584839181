import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import axios from 'axios';
import './Stripe.scss';

export default function StripeForm({ onComplete, onCancel, amount }) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  if (!stripe || !elements) {
    return <div>Loading....</div>;
  }
  const handleSubmit = async e => {
    setError(null);
    e.preventDefault();
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/complete'
      },
      redirect: 'if_required'
    });
    if (result.error) {
      setError(result.error.message);
    } else {
      const {
        data: { clown }
      } = await axios.put('/payment-intents', { id: result.paymentIntent.id });
      if (onComplete) {
        onComplete(clown);
      }
      console.log('Payment Success');
    }
  };
  return (
    <form onSubmit={handleSubmit} className="stripe-form-wrapper">
      {amount}
      <PaymentElement />
      {error}
      <div className="button-wrapper">
        <button>Submit</button>
        <button onClick={onCancel} className="cancel_btn">
          Cancel
        </button>
      </div>
    </form>
  );
}
