import i18next from 'i18next';
import React from 'react';
import { useEffect } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import useLanguageStorage from '../../hooks/useLanguageStorage';
import Router from '../Router/Router';
import Modal from 'react-modal';
Modal.setAppElement('#modal');
Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    inset: 0,
    zIndex: 999,
    backgroundColor: `rgba(0, 0, 0, 0.75)`
  },
  content: {
    position: 'absolute',
    inset: '40px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(255, 255, 255)',
    overflow: 'auto',
    borderRadius: ' 4px',
    outline: 'none',
    padding: '20px'
  }
};
i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        welcome: 'Welcome',
        HOME: 'HOME',
        GALLERY: 'GALLERY',
        GAMES: 'GAMES',
        INTRO: 'An infinite AI 🤡 generator',
        JOIN: 'JOIN THE CIRCUS',
        SIGNUP: 'Sign-up to receive updates and a chance for prize giveaways.',
        PRESS: 'Press button below to play...',
        CREDIT: 'CREDIT',
        COPYRIGHT: '©️ 2024 JUSTIN TRUDEAU IS A CLOWN',
        WALL: 'Wall of Clowns',
        GAMESECTION: 'Games',
        CONTACT: 'Contact Us',
        RECENT: 'SUPER SIGNERS',
        HEIGHT: 'Height',
        WIDTH: 'Width',
        SEED: 'Seed',
        PREFIX: 'File prefix',
        GUIDANCE: 'Guidance scale',
        MODEL: 'Model',
        REVISION: 'Revision',
        STEPS: 'Steps',
        SAMPLER: 'Sampler',
        DETAILS: 'AI Image details',
        CLOWN: 'Clown',
        NEWGAME: 'New game added',
        JTCASHGRAB: "JT's Cash Grab",
        GAMETEXT1:
          "When running for prime minister in the 2015 election, Trudeau promised Canadians he would balance the budget in 2019. The Canadian Taxpayer's Federation's national",
        GAMETEXT2:
          'shows the country\'s debt is now more than $1.2 trillion and growing every day. Help Justin collect all the "coins" to pay for his government\'s',
        GAMELINK1: 'Debt Clock',
        GAMELINK2: 'soaring spending',
        HASHTAG: '#trudeauisaclown',
        CLICK: 'Click to play',
        WIN: 'Win! Win! Win!',
        KEYCHAIN1A: `Collect a high score of more than `,
        KEYCHAIN1B: ` for a chance to win this collector's edition Trudeau clown keychain.`,
        KEYCHAIN1SCORE: `$30,000`,
        KEYCHAIN2A:
          'The first 20 people to submit their winning game screenshots to',
        KEYCHAIN2B: 'will be contacted.',
        CONTINUE: 'Continue',
        EMAIL: 'Email (will not be displayed)',
        NAME: 'Name/Nickname',
        OTHER: 'Other',
        SUPER1: 'Super sign',
        SUPER2: 'your AI clown',
        SUPERINFO:
          'AI image processing is gpu/resource intensive and the cloud servers are costly to run. Help keep this website running and support future development, new games, etc. Your support is greatly appreciated.',
        GENERATING1: 'GENERATING AI...',
        GENERATING2: 'HOLD UP, REGENERATING AI...',
        GENERATINGCLOWNS: `C<span>l</span>own<span>s</span> Gen<span>e</span>rat<span>e</span>d`,
        SIGNEDBY: 'Super signed by',
        SORT1: 'Sort:',
        SORT2: 'Super signed',
        SORT3: 'All clowns'
      }
    },
    fr: {
      translation: {
        welcome: 'Bienvenue',
        HOME: 'ACCUEIL',
        GALLERY: 'GALERIE',
        GAMES: 'JEUX',
        INTRO: "Un générateur 🤡 d'ai infini",
        JOIN: 'REJOINDRE LE CIRQUE',
        SIGNUP:
          'Inscrivez-vous pour recevoir des mises à jour et une chance de gagner des prix hebdomadaires.',
        PRESS: 'Appuyez sur le bouton pour jouer...',
        CREDIT: 'CRÉDIT',
        COPYRIGHT: '©️ 2024 PARLEMENT DE CLOWNTOWN',
        WALL: 'Mur des Bouffons',
        GAMESECTION: 'Jeux',
        CONTACT: 'Contactez-nous',
        RECENT: 'RÉCEMMENT',
        HEIGHT: 'Hauteur',
        WIDTH: 'Largeur',
        SEED: 'Graine',
        PREFIX: 'Préfixe de fichier',
        GUIDANCE: 'Échelle de guidage',
        MODEL: 'Modèle',
        REVISION: 'Révision',
        STEPS: 'Pas',
        SAMPLER: 'Échantillonneuse',
        DETAILS: "Détails de l'image",
        CLOWN: 'Bouffon',
        NEWGAME: 'Nouveau jeu ajouté',
        JTCASHGRAB: "L'argent de JT",
        GAMETEXT1:
          "Lors de sa candidature au poste de premier ministre aux élections de 2015, Trudeau a promis aux Canadiens qu'il équilibrerait le budget en 2019. Association nationale de la Fédération canadienne des contribuables",
        GAMETEXT2:
          'montre que la dette du pays est maintenant supérieure à 1,2 billion de dollars et augmente chaque jour. Aidez Justin à collecter toutes les "pièces" pour payer les dépenses de son gouvernement',
        GAMELINK1: 'Horloge de la dette',
        GAMELINK2: 'la flambée des dépenses',
        HASHTAG: '#trudeauestunbouffon',
        CLICK: 'Cliquez pour jouer',
        WIN: 'Gagner! Gagner!',
        KEYCHAIN1A: `Obtenez un score élevé de plus de `,
        KEYCHAIN1B: ` et gagnez ce porte-clés édition collector.`,
        KEYCHAIN1SCORE: `$30,000`,
        KEYCHAIN2A:
          "Les 20 premières personnes à soumettre leurs captures d'écran de jeu à",
        KEYCHAIN2B: 'seront contactés.',
        CONTINUE: 'Continuer',
        EMAIL: 'Email (pas affichée)',
        NAME: 'Nom/Surnom',
        OTHER: 'Autre',
        SUPER1: 'Super signe',
        SUPER2: 'votre clown',
        SUPERINFO:
          "Le traitement d'images par IA est gourmand en GPU/ressources et les serveurs cloud sont coûteux à exploiter. Votre soutien aide à faire fonctionner ce site Web ainsi que tout développement futur.",
        GENERATING1: "GÉNÉRER DE L'IA...",
        GENERATING2: 'HOLD UP, IA RÉGÉNÉRANTE...',
        GENERATINGCLOWNS: `B<span>o</span>uffon<span>s</span> Gén<span>é</span>r<span>é</span>s`,
        SIGNEDBY: 'Super signé par',
        SORT1: 'Trier par:',
        SORT2: 'Super signe',
        SORT3: 'Tous les clowns'
      }
    }
  },
  lng: 'en',
  fallbackLng: 'fr'
});

function App() {
  const { i18n } = useTranslation();
  const [language] = useLanguageStorage();
  useEffect(() => {
    i18n.changeLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Render app UI ...
  return (
    <div className="App">
      <div className="bg-wrapper">
        <Router />
      </div>
    </div>
  );
}

export default App;
