import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useState } from 'react';
import ReactModal from 'react-modal';
import StripeForm from './StripeForm';
import { Link, useParams } from 'react-router-dom';
import './Stripe.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '36%',
    width: '500px',
    height: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'translate(-50%, -50%)'
  }
};

export default function SuperSignStripeElement({
  clientSecret,
  amount,
  onCancel
}) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const [modalOpen, setModalOpen] = useState(true);
  const [succeeded, setSucceeded] = useState(false);
  const [clown, setClown] = useState();

  const { clownId } = useParams();
  useEffect(() => {
    axios
      .get('/clowns/' + clownId)
      .then(sleeper(900))
      .then(response => {
        setClown(response.data);
      })
      .catch(() => {
        setClown(null);
      });
    window.scrollTo(0, 0);
  }, [clownId]);

  function sleeper(ms) {
    return function (x) {
      return new Promise(resolve => setTimeout(() => resolve(x), ms));
    };
  }
  const hostUrl = `${process.env.REACT_APP_BACKEND_URL}/c/${clown?.shortlink}`;

  console.log('** hostUrl', hostUrl);

  const socialList = [
    {
      id: '1',
      class: 'fa-brands fa-square-twitter',
      img: `/images/square-twitter.svg`,
      callback: event => {
        event.preventDefault();
        axios.get('/tweet?url=' + hostUrl).finally(() => {
          setTimeout(
            () =>
              window.open(`http://twitter.com/intent/tweet?text=${hostUrl}`),
            400
          );
        });
      },
      url: `#`,
      alt: 'twitter icon'
    },
    {
      id: '2',
      class: 'fa-brands fa-square-facebook',
      img: `/images/square-facebook.svg`,
      alt: 'facebook icon',
      callback: () => {},
      url: `https://www.facebook.com/sharer.php?u=${hostUrl}`
    }
  ];

  return (
    <div className="superclown-wrapper">
      <div className="superclown-screen">
        <div className="superclown-content">
          {succeeded && (
            <>
              {localStorage.setItem('supersign', true)}
              <div className="superclown-title">Payment Successful</div>
              <p>Thanks for your support!</p>
              <p>A copy of your receipt has been sent to your email.</p>
              <Link to={`/c/${clown.shortlink}`} className="view-clown">
                View your signed clown
              </Link>
              <p className="share">Share it with others:</p>
              <div className="payment-social">
                {socialList.map(social => {
                  return (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      onClick={social.callback}
                      href={social.url}
                      key={social.id}>
                      <img
                        src={social.img}
                        alt={social.alt}
                        className={social.class}
                      />
                    </a>
                  );
                })}
              </div>
            </>
          )}

          <ReactModal
            onRequestClose={() => {
              console.log('On Request Close');
              setModalOpen(false);
              //setSucceeded(false);
              onCancel();
            }}
            isOpen={modalOpen}
            style={customStyles}
            onAfterClose={() => {
              setModalOpen(false);
              //setSucceeded(false);
            }}
            shouldCloseOnOverlayClick={true}>
            <div className="superclown-title pay-up">
              Super sign amount: ${amount} (CAD)
            </div>
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <StripeForm
                onCancel={onCancel}
                onComplete={clown => {
                  setModalOpen(false);
                  setSucceeded(true);
                  setClown(clown);
                }}
              />
            </Elements>
          </ReactModal>
        </div>
      </div>
    </div>
  );
}
